import DOMPurify from "isomorphic-dompurify";

export const secParseInt = (x: string | number | boolean | null): number => {
  try {
    return parseInt(x as never, 10);
  } catch (e) {
    return 0;
  }
};

export const secString = (val: string | number): string => {
  const value = DOMPurify.sanitize(val as never as string);
  const cleanValue = value.replace(/<.*?>/g, "");
  return cleanValue;
};
